<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-row class="px-6 mt-n16">
      <v-col class="mx-auto pt-0" lg="8">
        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Styles</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#0f88f2"
              >Button</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#0f88f2"
            >
              <v-icon size="14" class="me-4">ni-bag-17</v-icon>
              With Icon
            </v-btn>
            <v-btn
              min-width="56"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-4
                rounded-sm
              "
              color="#0f88f2"
            >
              <v-icon size="14">ni-atom</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Colors</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-default
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#172b4d"
              >Default</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-primary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#0f88f2"
              >Primary</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-secondary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#f7fafc"
              >Secondary</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-info
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#11cdef"
              >Info</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-success
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#2dce89"
              >Success</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-danger
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#f5365c"
              >Danger</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-warning
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="#fb6340"
              >Warning</v-btn
            >
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Outline</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-default
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Default</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-primary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Primary</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-secondary
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Secondary</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-info
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Info</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-success
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Success</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-danger
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Danger</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-outline-warning
                py-3
                px-6
                rounded-sm
                me-2
                my-2
              "
              color="transparent"
              >Warning</v-btn
            >
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Sizes</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn
              large
              elevation="0"
              :ripple="false"
              height="51"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-primary
                py-3
                px-4
                me-2
                my-2
              "
              color="#0f88f2"
              >Large Button</v-btn
            >
            <v-btn
              large
              elevation="0"
              :ripple="false"
              height="51"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-secondary
                py-3
                px-4
                me-2
                my-2
              "
              color="#f7fafc"
              >Large Button</v-btn
            >
            <div class="border-bottom my-8"></div>
            <v-btn
              small
              elevation="0"
              :ripple="false"
              height="28"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-primary
                rounded-sm
                px-2
                py-1
                me-2
                my-2
              "
              color="#0f88f2"
              >Small Button</v-btn
            >
            <v-btn
              small
              elevation="0"
              :ripple="false"
              height="28"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-secondary
                rounded-sm
                px-2
                py-1
                me-2
                my-2
              "
              color="#f7fafc"
              >Small Button</v-btn
            >
            <div class="border-bottom my-8"></div>
            <v-btn
              block
              large
              elevation="0"
              :ripple="false"
              height="51"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-primary
                py-3
                px-4
                me-2
                my-2
              "
              color="#0f88f2"
              >Block Level Button</v-btn
            >
            <v-btn
              block
              large
              elevation="0"
              :ripple="false"
              height="51"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-secondary
                py-3
                px-4
                me-2
                my-2
              "
              color="#f7fafc"
              >Block Level Button</v-btn
            >
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Group</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn-toggle v-model="toggle_exclusive" class="btn-toggle">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-secondary
                  py-3
                  px-6
                  border-0
                "
                color="#f7fafc"
              >
                Left
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-secondary
                  py-3
                  px-6
                  border-0
                "
                color="#f7fafc"
              >
                Middle
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-secondary
                  py-3
                  px-6
                  border-0
                "
                color="#f7fafc"
              >
                Right
              </v-btn>
            </v-btn-toggle>
            <div class="border-bottom my-8"></div>
            <v-btn-toggle
              v-model="toggle_exclusive1"
              class="btn-toggle btn-toggle-info"
            >
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-info
                  py-3
                  px-6
                  border-0
                "
                color="#11cdef"
              >
                1
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-info
                  py-3
                  px-6
                  border-0
                "
                color="#11cdef"
              >
                2
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-info
                  py-3
                  px-6
                  border-0
                "
                color="#11cdef"
              >
                3
              </v-btn>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-info
                  py-3
                  px-6
                  border-0
                "
                color="#11cdef"
              >
                4
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="toggle_exclusive2" class="btn-toggle ms-3">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-default
                  py-3
                  px-6
                  border-0
                "
                color="#172b4d"
              >
                5
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-default
                  py-3
                  px-6
                  border-0
                "
                color="#172b4d"
              >
                6
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-default
                  py-3
                  px-6
                  border-0
                "
                color="#172b4d"
              >
                7
              </v-btn>
            </v-btn-toggle>
          </v-card-text>
        </v-card>

        <v-card class="card-shadow mb-30">
          <div class="card-header-padding card-border-bottom">
            <p class="font-weight-600 text-h3 text-typo mb-0">Social</p>
          </div>
          <v-card-text class="card-padding">
            <v-btn
              v-for="item in socialBtn"
              :key="item.name"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-default
                py-3
                px-6
                me-2
                my-2
                rounded-sm
              "
              :color="item.color"
            >
              <v-icon size="14" class="me-3">{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-btn>
            <div class="border-bottom my-8"></div>
            <v-btn
              v-for="item in socialBtn"
              :key="item.name + 1"
              elevation="0"
              :ripple="false"
              height="38"
              min-width="38"
              width="38"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-default
                me-2
                my-2
                rounded-sm
              "
              :color="item.color"
            >
              <v-icon size="14">{{ item.icon }}</v-icon>
            </v-btn>
            <div class="border-bottom my-8"></div>
            <v-btn
              v-for="item in socialBtn"
              :key="item.icon + 2"
              elevation="0"
              :ripple="false"
              height="38"
              min-width="38"
              width="38"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-default
                me-2
                my-2
                rounded-circle
              "
              :color="item.color"
            >
              <v-icon size="14">{{ item.icon }}</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "buttons",
  components: {
    HeaderTopDashboard,
  },
  data() {
    return {
      toggle_exclusive: 1,
      toggle_exclusive1: 0,
      toggle_exclusive2: false,
      socialBtn: [
        {
          color: "#3b5999",
          icon: "fab fa-facebook",
          name: "Facebook",
        },
        {
          color: "#1da1f2",
          icon: "fab fa-twitter",
          name: "Twitter",
        },
        {
          color: "#dd4b39",
          icon: "fab fa-google-plus-g",
          name: "Google +",
        },
        {
          color: "#e4405f",
          icon: "fab fa-instagram",
          name: "Instagram",
        },
        {
          color: "#bd081c",
          icon: "fab fa-pinterest",
          name: "Pinterest",
        },
        {
          color: "#cd201f",
          icon: "fab fa-youtube",
          name: "Youtube",
        },
        {
          color: "#04a0f0",
          icon: "fab fa-vimeo-v",
          name: "Vimeo",
        },
        {
          color: "#3aaf85",
          icon: "fab fa-slack",
          name: "Slack",
        },
        {
          color: "#ea4c89",
          icon: "fab fa-dribbble",
          name: "Dribbble",
        },
      ],
    };
  },
};
</script>
